/**
 * 交易区/币种交易状态
 */
export enum CoinTradingStatusTypeEnum {
  /** 开启 */
  enable = 'ENABLE',
  /** 关闭 */
  disable = 'DISABLE',
}

/**
 * 交易区交易类型
 */
export enum AreaTransactionTypeEnum {
  /** 全部 */
  all = 'ALL',
  /** 站内 */
  inside = 'INSIDE',
  /** 站外 */
  outside = 'OUTSIDE',
}

/**
 * 订单 - 买卖角色类型
 */
export enum OrderBuyAndSellRoleTypeEnum {
  /** 购买 */
  buyer = 'BUYER',
  /** 出售 */
  seller = 'SELLER',
}

/**
 * c2c mode
 */
export enum c2cModeEnum {
  private = 'private',
  public = 'public',
}

/**
 * private c2c exchange - Monkey
 * public c2c exchange - FastPay
 */
export enum c2cBusinessType {
  privateC2c = 'monkey',
  publicC2c = 'fastPay',
}

/**
 * 法币充值相关接口路径（不需要关联 c2c 公有化）
 */
export const LegalCurrencyRechargeApiList = [
  '/v1/c2c/pay/charge/info',
  '/v1/c2c/pay/fiat/currency',
  '/v1/c2c/pay/withdraw/info',
  '/v1/c2c/pay/charge',
  '/v1/c2c/pay/withdraw',
  '/v1/c2c/pay/footer',
  '/v/c2c/pay/default/currency',
  '/v1/c2c/pay/pre-withdraw',
  '/v1/c2c/commonSetting/trade/channel',
  '/v1/c2c/pay/default/fiat/currency',
  '/v1/c2c/pay/rate',
]
